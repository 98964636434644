const typography = {
EDIT_PROFILE: "Edit Profile",
UPDATE: "Update",
NAME: "Name",
EMAIL: "Email",
CURRENT_PASSWORD: "Current Password",
NEw_PASSWORD: "New Password",
REENTER_PASSWORD: "Re Enter Password",
ENTER_EMAIL: "Enter Email",
ENTER_NAME: "Enter Name",
ENTER_PASSWORD: "Enter Password",
ENTER_NEW_PASSWORD: "Enter New Password",
ENTER_REENTER_PASSWORD: "Enter New Password Again",
}

export default typography