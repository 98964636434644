import { SearchInput } from "../../components/SearchInput";
import Filters from "./Filters";
import typography from "./typography";


export default function Header({
statusFilterVal = "",
statusFilterUpdateHandler=() => {},
planFilterUpdateHandler=() => {},
planFilterVal="",
searchTextVal="",
searchValUpdateHandler=() => {}
}) {

  
  const statusFilterConfig = [
    {
      id : 1,
      title : typography.ALL,
    },
    {
      id: 2,
      title: typography.ACTIVE,
    },
    {
      id: 3,
      title: typography.INACTIVE,
    }
  ]
  const planFilterConfig = [
    {
      id : 1,
      title : typography.ALL,
    },
    {
      id: 2,
      title: typography.FREE,
    },
    {
      id: 3,
      title: typography.PREMIUM,
    }
  ]

  return(
    <div className="my-4  header-con">
      <div className="filters-parent-con">
        <Filters 
        activeTab={statusFilterVal}
        tabClickHandler={statusFilterUpdateHandler}
        config = {statusFilterConfig}
        className="me-5 status-filter-con"
        label="Status"/>
        <Filters 
        activeTab={planFilterVal}
        tabClickHandler={planFilterUpdateHandler}
        config = {planFilterConfig}
        className="plan-filter-con"
        label="Plan"/>
      </div>
      <div className="search-input-main-con">
      <SearchInput
      placeholder={"Search"}
      type="text"
      onChange={e => searchValUpdateHandler(e.target.value)}
      value={searchTextVal}
        />
      </div> 
    </div>
  )
}