import { Col, Row } from "reactstrap";
import typography from "./typography";

export default function DetailsBox ({data={
    date: "",
    category: "",
    BLSDuration: "",
    feedback: "" 
}}) {
  return(
    <>
      <Row className="details-inner-con mx-auto">
        <Col md="6" xs="6">
          <div className="mb-4">
            <h6 className="standard-typo-14-400 primary-color mb-0">{typography.DATE}</h6>
            <p className="standard-typo-16-600 ">{data.date}</p>
          </div>
          <div >
            <h6 className="standard-typo-14-400 primary-color mb-0">{typography.BLS_DURATION}</h6>
            <p className="standard-typo-16-600">{data.BLSDuration}</p>
          </div>
        </Col>
        <Col md="6" xs="6" >
          <div className="mb-4">
            <h6 className="standard-typo-14-400 primary-color mb-0">{typography.CATEGORY}</h6>
            <p className="standard-typo-16-600">{data.category}</p>
          </div>
          <div>
            <h6 className="standard-typo-14-400 primary-color mb-0">{typography.FEEDBACK}</h6>
            <p className="standard-typo-16-600">{data.feedback}</p>
          </div>                         
        </Col>
      </Row>
    </>
  )
}