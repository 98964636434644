import { Get, Put, Post, Delete } from "./api.helper";
import { store } from "store";
import Config from "Config";
import { appendQueryParams } from "utils/urlUtils";

// ==== Authentication
export const Login = (bodyData) => {
    return Post({
        url: "/auth/login",
        body: bodyData,
        isAuthorized: false
    });
};

export const GetMe = () => {
    return Get({ url: "/user" });
};

export const ForgotPassword = (bodyData) => {
    return Post({ url: "/user/forget-password", body: bodyData });
};

export const ForgotPasswordVerification = (bodyData) => {
    return Post({
        url: "/user/forget-password-verification",
        body: bodyData
    });
};

export const ResetPassword = (bodyData) => {
    return Post({ url: "/user/reset-password", body: bodyData });
};

export const VerifyUser = (bodyData) => {
    return Post({
        url: "/",
        body: bodyData,
        isAuthorized: false
    });
};

export const SignUp = (bodyData) => {
    return Post({
        url: "/",
        body: bodyData,
        isAuthorized: false
    });
};

// ALPHA LISTING API
export const ListingApi = (params = {}, abortSignal = null) => {
    return Get({ url: "/user/customers", params, abortSignal });
};

//========================
// Examples of Api Calling
//========================

// GET

// const GetApiExample = (params = {}, abortSignal = null) => {
//     return Get({ url: "/url", params, abortSignal });
// };

// // GET BY ID

// const GetByIdApiExample = (id) => {
//     return Get({ url: `/url/${id}` });
// };

// // POST
// const PostApiExample = (bodyData) => {
//     return Post({
//         url: "/url",
//         body: bodyData
//     });
// };
// // POST BY ID

// const PostByIdApiExample = ({ id, bodyData }) => {
//     return Post({
//         url: `/url${id}`,
//         body: bodyData
//     });
// };
// // PUT

// const PutApiExample = (bodyData) => {
//     return Put({
//         url: "/url",
//         body: bodyData
//     });
// };
// // PUT BY ID

// const PutByIdApiExample = ({ id, bodyData }) => {
//     return Put({
//         url: `/url${id}`,
//         body: bodyData
//     });
// };
// //  DELETE
// const DeleteApiExample = (id) => {
//     return Delete({
//         url: `/url/${id}`
//     });
// };
