import { injectBaseConstantMethods } from "./BaseConstants";

const Plans = {
    Free: "free",
    Premium: "premium"
};

const displayTextKeys = {
    [Plans.Free]: "Free",
    [Plans.Premium]: "Premium"
};

const labelClass = {
    [Plans.Free]: "plan-free",
    [Plans.Premium]: "plan-premium"
};

export default injectBaseConstantMethods(Plans, displayTextKeys, labelClass);
