import React from "react";
import PropTypes from "prop-types";
import { Label, Input } from "reactstrap";

const RowsLimit = ({ options, changeHandler = () => {} }) => {
    return (
        <div className={`d-flex align-items-center sorting-con`}>
            <span className="ms-4 me-3 mb-0 standard-typo-14-400">Show</span>
            <Input
                type="select"
                name="select"
                id="exampleSelect"
                onChange={changeHandler}
                className="sorting-dropdown"
            >
                {options &&
                    options.map((el, index) => (
                        <option value={el.value} key={index} className="opt">
                            {el.label}
                        </option>
                    ))}
            </Input>
        </div>
    );
};

RowsLimit.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })
    ),
    changeHandler: PropTypes.func
};

export default RowsLimit;
