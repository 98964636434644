import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { withRouter, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";

import AuthWrapper from "./AuthWrapper";
import { CustomModal } from "../../components/CustomModal";
import { CodeInput } from "../../components/CodeInput";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "helpers/routeHelpers";
import { ForgotPasswordVerification, ForgotPassword } from "api/api.service";
import forgetImage from "assets/images/forget-pass-img.png";
import { PageTitles } from "../../constants";
import Headings from "./Headings";
import { Row, Col, Label } from "reactstrap";

const OTP_LENGTH = 4;

const ForgetVerifyCode = ({}) => {
    const history = useHistory();
    const location = useLocation();
    const { tokenId } = useParams();
    let isAuthenticated = useSelector((state) => state?.userAuth?.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    // Alpha
    const [codeAndToken, setCodeAndToken] = useState({ code: "0000", tokenUId: "01010101" });
    const [isResendCode, setIsResendCode] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);

    useEffect(() => {
        if (!tokenId) {
            return;
        }

        setToken(tokenId);
        setEmail(location?.state?.email || "");
    }, [tokenId]);

    const handleSubmit =
        //  async
        (e) => {
            // Alpha
            e.preventDefault();
            setShowOtpModal(true);

            return;
            // e.preventDefault();
            // try {
            //     setIsLoading(true);

            //     let body = {
            //         Code: OTP,
            //         TokenUUID: token
            //     };
            //     let res = await ForgotPasswordVerification(body);
            //     let { Code, TokenUUID } = res;
            //     setCodeAndToken({ code: Code, tokenUId: TokenUUID });
            //     setShowOtpModal(true);
            // } finally {
            //     setIsLoading(false);
            // }
        };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true);
            let res = await ForgotPassword({ Email: email });
            toast.success("An OTP has been send to your email address !");
            history.push({
                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                    tokenId: res?.TokenUUID
                }),
                state: { email }
            });
        } finally {
            setIsResendCode(false);
            setOTP("");
        }
    };

    if (!location?.state?.email) {
        <Redirect to={APP_ROUTES.BLS_FEEDBACK} />;
    }

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.BLS_FEEDBACK} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.OTP_VERIFICATION}</title>
            </Helmet>
            <AuthWrapper
            // leftContent={
            //     <div className="text-center">
            //         <h1 className="text-white fw-normal">
            //             <span>ONE TIME PASSWORD</span>
            //             <span className="d-block font-size-14">
            //                 Don't share this code with anyone else.
            //             </span>
            //         </h1>
            //     </div>
            // }
            // leftImgUrl={forgetImage}
            >
                <div className="bv-forget-wrap">
                    <Headings title="OTP Verification" subText="Reset Your Password" />

                    <form className="mt-5" onSubmit={handleSubmit}>
                        <h6 className="font-clr-theme-secondary mb-4 font-size-12 text-left p-0">
                        Please enter One Time Password (OTP) sent to your email
                        </h6>
                        <Row>
                            <Col md={12}>
                                <Label>
                                Enter OTP
                                </Label>
                                <CodeInput
                                    className="my-4 text-center"
                                    onChange={(e) => setOTP(e)}
                                />
                            </Col>
                        </Row>
                        <CustomButton
                            disabled={OTP.length != OTP_LENGTH}
                            loading={isLoading}
                            onClick={handleSubmit}
                            color="primary"
                            type={"submit"}
                            title="Submit"
                            className="w-100 mt-4"
                        />
                    </form>
                    <div
                        className="d-flex mt-4 align-items-center justify-content-center"
                        onClick={handleResendCode}
                    >
                        <p className="standard-typo-14-400"> Didn’t Receive Yet ? </p>
                        <Link className="standard-typo-14-600 ms-1 fw-600">
                            {isResendCode ? "Sending..." : "Resend Now"}
                        </Link>
                    </div>
                    <div>
                        <Link
                            className="d-flex align-items-center justify-content-center my-4"
                            to={APP_ROUTES.LOGIN}
                        >
                            <p className="standard-typo-14-600"> Back to Login </p>
                        </Link>
                    </div>
                </div>
            </AuthWrapper>
            <CustomModal isOpen={showOtpModal} size="md">
                <div className="text-center">
                    <h2 className="font-clr-theme-secondary font-family-lexend">OTP Verified Successfully</h2>
                    <p className="my-4 font-family-lexend fw-500 font-size-14">
                        One Time Password has been Verified successfully. <br /> Continue to reset
                        your password
                    </p>
                    <CustomButton
                        color="primary"
                        title="Continue"
                        className="custom-btn-sm  mb-5"
                        onClick={() => {
                            history.push(
                                getParamsAttachedRoute(APP_ROUTES.RESET_PASSWORD, {
                                    code: codeAndToken?.code,
                                    tokenId: codeAndToken?.tokenUId
                                })
                            );
                        }}
                    />
                </div>
            </CustomModal>
        </>
    );
};

export default withRouter(ForgetVerifyCode);
