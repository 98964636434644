import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Label } from "reactstrap";
import { CustomModal } from "../../components/CustomModal";
import { useSelector } from "react-redux";
import { withRouter, Redirect, useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import AuthWrapper from "./AuthWrapper";
import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import forgetImage from "../../assets/images/forget-pass-img.png";
import { ForgotPassword } from "api/api.service";
import { PageTitles } from "../../constants";
import Headings from "./Headings";

const ForgetPassword = ({}) => {
    const history = useHistory();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    // Alpha --------------
    const [holdToken, setHoldToken] = useState("0annba87abbbba6hhg7");
    const [holdEmail, setHoldEmail] = useState("exampleemail@mailinator.com");
    const [showOtpModal, setShowOtpModal] = useState(false);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Enter a valid email address")
                .required("Enter email address")
                .min(6, "Too short email!")
                .max(50, "Too long email!")
                .matches(
                    /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
                    "Enter valid email address"
                )
        }),
        onSubmit: (values) => {
            handleEmailSubmit(values);
        }
    });

    const handleEmailSubmit = async (values) => {
        // Alpha --------------
        setShowOtpModal(true);
        return;
        // ---------------------

        const { email } = values;
        try {
            setIsLoading(true);
            let res = await ForgotPassword({ Email: email });
            // toast.success("An OTP has been send to your email address !");
            setHoldEmail(email);
            setHoldToken(res?.TokenUUID);
            setShowOtpModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.BLS_FEEDBACK} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.FORGET_PASSWORD}</title>
            </Helmet>
            <AuthWrapper
            // leftContent={
            //     <div className="text-center">
            //         <h1 className="text-white fw-normal">
            //             <span>CONFIRM EMAIL ADDRESS</span>
            //             <span className="d-block font-size-14">
            //                 Confirm your email address to reset your password
            //             </span>
            //         </h1>
            //     </div>
            // }
            // leftImgUrl={forgetImage}
            >
                <div className="bv-forget-wrap">
                    <Headings title="Forgot Password?" subText="Reset Your Password" />

                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <p className="mb-4  text-left p-0 standard-typo-14-400">
                        Enter your email to receive One time password (OTP)
                        </p>
                        <Label>Email</Label>
 
                        <div className="mb-4">
                            <TextInput
                                placeholder="Enter Email"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                className="hide-default-icon"
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <span className="error">{formik.errors.email}</span>
                            ) : null}
                        </div>

                        <CustomButton
                            loading={isLoading}
                            color="primary"
                            title="Send OTP"
                            className="w-100"
                        />
                        <div>
                            <Link
                                className="d-flex align-items-center justify-content-center my-4"
                                to={APP_ROUTES.LOGIN}
                            >
                                <p className="standard-typo-14-600 "> Back to Login </p>
                            </Link>
                        </div>
                    </form>
                </div>
            </AuthWrapper>
            <CustomModal isOpen={showOtpModal} size="md">
                <div className="text-center">
                    <h2 className="font-clr-theme-secondary font-family-lexend">OTP Sent</h2>
                    <p className="my-4 font-family-lexend fw-500 font-size-14">
                        One Time Password has been sent to your provided <br /> email address.
                    </p>
                    <CustomButton
                        color="primary"
                        title="Continue"
                        className="custom-btn-sm mb-5"
                        onClick={() =>
                            history.push({
                                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                                    tokenId: holdToken
                                }),
                                state: { email: holdEmail }
                            })
                        }
                    />
                </div>
            </CustomModal>
        </>
    );
};

export default withRouter(ForgetPassword);
