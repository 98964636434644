export const getCommaSeparatedNumber = (amount) => {
    return new Intl.NumberFormat("en-US").format(amount);
};

export const getFormattedAmountWithCurrency = (amount, currency = "AED") => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency || "USD",
        minimumFractionDigits: 0
    });
    return formatter.format(amount);
};

export const escapeHTMLElementsFromMessage = (message) => {
    //todo places in util helper
    var htmlEscapes = {
        "<": "&lt;",
        ">": "&gt;"
    };
    // Regex containing the keys listed immediately above.
    var htmlEscaper = /[<>]/g;
    // Escape a string for HTML interpolation.
    return message.replace(htmlEscaper, function (match) {
        return htmlEscapes[match];
    });
};

export const getObjectByKeys = (arr, key = "Id", deleteKey = null, addKeys = null) => {
    let obj = {};
    arr.forEach((val) => {
        obj[val[key]] = val;
        if (deleteKey) {
            delete obj[val[key]][deleteKey];
        }
        if (addKeys) {
            obj[val[key]] = {
                ...obj[val[key]],
                ...addKeys
            };
        }
    });
    return obj;
};

export const getIdsFromData = (data, key = "Id") => {
    return data.map((item) => item[key]);
};

export const objectContainsKey = (object, key) => {
    return typeof object === "object" && object && object[key] !== undefined;
};

export const getPhoneNumber = (data) => {
    if (data && data.CountryCode && data.PhoneNumber) {
        return `${data.CountryCode} ${data.PhoneNumber}`;
    }
    return "-";
};

export const prepareTableColumnState = (columnData) => {
    let obj = {};
    Object.keys(columnData).forEach((key) => {
        obj[columnData[key]] = {
            title: columnData[key],
            isShown: true
        };
    });

    return obj;
};

export const getObjectFromKeyValue = (
    arr = [],
    key = "",
    subKey = "",
    getValueKey = "",
    value = ""
) => {
    if (!arr?.length) return;
    if (!arr.find((item) => item[key]).length) return;

    return arr.find((item) => item[key][subKey] == value)[getValueKey];
};

export const removeDuplicatValFromArray = (arr) => {
    if (!arr.length) {
        return;
    }

    return [...new Set(arr)];
};

export const replaceStrings = (str, keyToFind, keysToReplaceObj = {}) => {
    return str.replace(keyToFind, (matched) => {
        return keysToReplaceObj[matched];
    });
};

export const truncateString = (string = "", maxLength) => {
    if (!string || !maxLength) return
    return string.length > maxLength
      ? `${string.substring(0, maxLength)}…`
      : string
  }
