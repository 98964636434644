import React from "react";
import { Container } from "reactstrap";

import "./Styles.scss";
import APP_CONFIG from "APP_CONFIG";

const AuthWrapper = ({ className = "", children }) => {
    return (
        <div className={`${className} app-auth-wrapper d-flex align-items-stretch min-vh-100`}>
            <div
                className="auth-wrap-left-content col-md-8 col-lg-8  d-none d-md-block d-flex align-items-center"
                style={{
                    background: `url(${APP_CONFIG.IMAGES.LOGIN_LEFT_BG_IMAGE}) no-repeat center/cover`
                }} 
            >
                <Container className="h-100">
                    <div className="d-flex flex-column justify-content-evenly h-100">
                        <div className="text-center">
                            <h1 className="text-white fw-normal">
                                <span>{APP_CONFIG.LOGIN_LEFT_MAIN_TEXT}</span>
                                <span className="d-block font-size-14">
                                    {APP_CONFIG.LOGIN_LEFT_SUB_TEXT}
                                </span>
                            </h1>
                        </div>

                        {/* <img
                            src={APP_CONFIG.IMAGES.LOGIN_LEFT_MAIN_IMAGE}
                            className="main-image img-fluid mx-auto d-block col-md-4 mt-5"
                        /> */}
                    </div>
                </Container>
            </div>

            {/* <div className="auth-wrap-right-content  col-md-4 col-sm-12 col-lg-4 col-xs-12 d-flex align-items-center"> */}
            <Container>
                <div className=" offset-xxl-2 col-xxl-8 offset-md-1 col-md-10 auth-wrap-right-content ">
                    <img src={APP_CONFIG.LOGIN_RIGHT_IMAGE} className="img-fluid mx-auto d-block" />
                    {children}
                </div>
            </Container>
            {/* </div> */}
        </div>
    );
};

export default AuthWrapper;
