import { Col } from "reactstrap"
import { findOutClassStatBoxes } from "./Utility"
import typography from "./typography"

export default function StatBoxes () {

  const STATE_BOXES = [
    {
        HEADING: typography.BLS_THERAPY_COUNT,
        BOTTOM_TEXT: typography.TOTAL_SESSION_TAKEN,
        COUNT: "40"
    },{
        HEADING: typography.TOTAL_RESPONSES,
        BOTTOM_TEXT: typography.SUBMITTED_BY_USER,
        COUNT: "28"
    },
    {
        HEADING: typography.AVERAGE_RATING,
        BOTTOM_TEXT: typography.ALL_USER_FEEDBACK,
        COUNT: "7",
        RATIO_COUNT: "10",
    }
  ]

  return(
    <>
      {
        STATE_BOXES.map( (el, idx) => {
            return (
                    <Col xl="4" md="6" sm="6" xs="12" className="mb-3" key={idx}>
                        <div className={`stat-boxes-con p-3 ${findOutClassStatBoxes(el.HEADING)}`} >
                            <p className="standard-typo-14-500-ld ft-white">{el.HEADING}</p>
                            <h1 className="ft-white stat-box-heading font-family-lexend">{el.COUNT}<span>{el?.RATIO_COUNT ? `/${el?.RATIO_COUNT}` : null}</span></h1>
                            <div className="d-flex justify-content-end ft-white">
                                <p className="standard-typo-12-400-ld ft-white">{el.BOTTOM_TEXT}</p>
                            </div>
                        </div>
                    </Col>
            )
        })
    }
    </>
  )
}