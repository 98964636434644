import { findBarPercentage } from "../../pages/BLSFeedback/Utility";
import EcllipseIcon from '../../assets/images/app/common/aou-rating-input-ecllipse.svg';
export default function RatingBarInput({
  rating= 6,
  innerClass="",
  containerClass="",
  icon=false
}) {
  return(
    <>
      <div className="d-flex align-items-center">
          <div className={`${containerClass} me-2`}>
              <div className={`${innerClass} me-2`} style={{'width' : `${findBarPercentage(rating)}%`}}>
                {icon ? (<img src={EcllipseIcon} className="rating-bar-img"/>) : null}
              </div>
          </div>
          <div className="py-3 standard-typo-14-400">{`${rating || "-"}/10`}</div>
      </div>
    </>
  )
}