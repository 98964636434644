import React from "react";

import crossIcon from "assets/images/img-preview-cross.png";
import editIcon from "assets/images/app/common/aou-image-edit.svg";
import APP_CONFIG from "../../APP_CONFIG";
import "./Style.scss";

const ImageBox = ({
    containerClasses = "",
    crossClasses = "",
    editClasses = "",
    imageToPreview = "",
    imageClasses = "",
    isRemoveable = false,
    isEditable = true,
    handleRemoveImage = () => {},
    handleEditImage = () => {}
}) => {
    return (
        <div className={`preview-container ${containerClasses}`}>
            {/* Cross Button  */}
            {isRemoveable && (
                <img
                    role="button"
                    className={`cross-button ${crossClasses}`}
                    src={crossIcon}
                    onClick={handleRemoveImage}
                />
            )}
            {/* Edit Button  */}
            {isEditable && (
                <img
                    role="button"
                    className={`edit-button ${editClasses}`}
                    src={editIcon}
                    onClick={handleEditImage}
                />
            )}
            {/* Image  */}
            <img
                src={imageToPreview || APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER}
                className={`preview-image ${imageClasses}`}
            />
        </div>
    );
};

export default ImageBox;
