import { fetchUtil } from "../../utils/fetchUtil";
import { ME_REQUEST, ME_SUCCESS, ME_ERROR, SET_TOKEN } from "./actionTypes";

import { Login, GetMe } from "api/api.service";

export const login = (bodyData) => (dispatch, getState) => {
    return Login(bodyData)
        .then(async (res) => {
            dispatch({ type: SET_TOKEN, token: res.token });
            await dispatch(getMe(res.token));
            return Promise.resolve({});
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getMe = (token) => (dispatch, getState) => {
    dispatch({ type: ME_REQUEST });
    return GetMe()
        .then((res) => {
            dispatch({ type: ME_SUCCESS, payload: res });
            localStorage.isAuthenticated = true;
            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({ type: ME_ERROR });
            return Promise.reject(err);
        });
};

export const updateFCMToken = (fcmToken) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/user/fcm",
        token,
        method: "PUT",
        body: JSON.stringify({
            FCMToken: fcmToken
        })
    })
        .then((res) => {
            if (res && res) {
                return Promise.resolve(res);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
