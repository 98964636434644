import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Label } from "reactstrap";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import toast from "react-hot-toast";

import AuthWrapper from "./AuthWrapper";
import { TextInput, PasswordInput } from "../../components/Input";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { login } from "store/actions";
import loginImage from "assets/images/login-img.png";
import APP_CONFIG from "APP_CONFIG";
import PageTitles from "../../constants/PageTitles";
import Headings from "./Headings";

const Login = (props) => {
    let {} = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Enter a valid email address")
                .required("Enter email address")
                .min(6, "Too short email!")
                .max(50, "Too long email!")
                .matches(
                    /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
                    "Enter valid email address"
                ),
            password: Yup.string().required("Enter Password")
            // .min(6, "Password should be minimum 6 characters long")
            // .max(25, "Password should be between 6 to 25 characters")
            // .test(
            //     "regex",
            //     "Password must contain characters, special characters and numbers",
            //     (val) => {
            //         let regExp = new RegExp(
            //             "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
            //         );
            //         return regExp.test(val);
            //     }
            // )
        }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { email, password } = values;
        try {
            setIsLoading(true);
            let res = await dispatch(
                login({
                    email,
                    password
                })
            );
            toast.success("Login successfully !");
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.LOGIN}</title>
            </Helmet>
            <AuthWrapper>
                <div>
                    <Headings title="Hey, There" subText="Welcome Back" isLogin={true} />
                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <Label>Email</Label>
                        <div className="mb-4">
                            <TextInput
                                autoComplete
                                placeholder="Enter Email"
                                name={"email"}
                                type="email"
                                className="hide-default-icon"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                autoFocus
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <span className="error">{formik.errors.email}</span>
                            ) : null}
                        </div>

                        <Label>Password</Label>
                        <div className="mb-4">
                            <PasswordInput
                                placeholder="Enter Password"
                                className="hide-default-icon"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <span className="error">{formik.errors.password}</span>
                            ) : null}
                        </div>
                        <div>
                            <Link
                                className="d-flex align-items-center justify-content-end my-4"
                                to={APP_ROUTES.FORGET_PASSWORD}
                            >
                                <p className="clr-theme-primary font-family-source-sans-600 forgot-pass-link"> Forgot Password? </p>
                            </Link>
                        </div>

                        <CustomButton
                            loading={isLoading}
                            type="submit"
                            color="primary"
                            title="Sign In"
                            className="w-100 "
                        />
                    </form>
                </div>
            </AuthWrapper>
        </>
    );
};

export default withRouter(Login);
