export default function ToggleInput ({
  checkedVal,
  trueContent= "",
  falseContent="",
  toggleHandler= () => {},
  defaultChecked
}) {
  return(
    <>
      <label class="switch">
        <input type="checkbox" checked={checkedVal} defaultChecked={defaultChecked} onClick={toggleHandler}/>
        <span class="slider"></span>
        <span class="text on">{trueContent}</span>
        <span class="text off">{falseContent}</span>
      </label>
    </>
  )
}