import { BLSFeedback } from "../pages/BLSFeedback"
import { CustomerManagement, CustomerDetails } from "../pages/CustomerManagement";

import { APP_ROUTES } from "../helpers/routeHelpers";
import { Roles } from "../constants";
import { EditProfile } from "../pages/EditProfile";

let { Admin } = Roles;

const authProtectedRoutes = [
    // BLSFeedback
    {
        path: APP_ROUTES.BLS_FEEDBACK,
        component: BLSFeedback,
        permissions: [Admin],
        exact: true
    },
    // Customer Management
    {
        path: APP_ROUTES.CUSTOMER_MANAGEMENT,
        component: CustomerManagement,
        permissions: [Admin],
        exact: true
    },
    {
        path: `${APP_ROUTES.CUSTOMER_DETAILS}/:id`,
        component: CustomerDetails,
        permissions: [Admin],
        exact: false
    },
     {
        path: APP_ROUTES.EDIT_PROFILE,
        component: EditProfile,
        permissions: [Admin],
        exact: true
    }
    // User Management

    // {
    //     path: APP_ROUTES.USER_MANAGEMENT,
    //     component: UserManagement,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.USER_MANAGEMENT_DETAILS,
    //     component: UserDetails,
    //     permissions: [Admin],
    //     exact: false
    // },
    // {
    //     path: APP_ROUTES.USER_MANAGEMENT_PROFILE,
    //     component: UserProfileDetails,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.USER_MANAGEMENT_CONNECTIONS,
    //     component: Connections,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.USER_MANAGEMENT_ROUNDS,
    //     component: Rounds,
    //     permissions: [Admin],
    //     exact: true
    // },
    // // Content Management

    // {
    //     path: APP_ROUTES.CONTENT_MANAGEMENT,
    //     component: ContentManagement,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.CONTENT_MANAGEMENT_ADD,
    //     component: <div></div>,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.CONTENT_MANAGEMENT_DETAILS,
    //     component: ContentDetails,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.CONTENT_MANAGEMENT_UPDATE,
    //     component: UpdateContent,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.CONTENT_MANAGEMENT_ADD,
    //     component: AddContent,
    //     permissions: [Admin],
    //     exact: true
    // },
    // // Ad Management

    // {
    //     path: APP_ROUTES.AD_MANAGEMENT,
    //     component: AdManagement,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.CREATE_AD,
    //     component: <div></div>,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.AD_DETAILS,
    //     component: AdDetails,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.UPDATE_AD,
    //     component: UpdateAd,
    //     permissions: [Admin],
    //     exact: true
    // },

    // //Course Management

    // {
    //     path: APP_ROUTES.COURSE_MANAGEMENT,
    //     component: CourseManagement,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.UPDATE_COURSE,
    //     component: UpdateCourse,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.COURSE_MANAGEMENT_DETAILS,
    //     component: CourseDetailsPage,
    //     permissions: [Admin],
    //     exact: false
    // },

    // {
    //     path: APP_ROUTES.COURSE_DETAILS,
    //     component: DetailsCourse,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.COURSE_DETAILS_ROUNDS,
    //     component: DetailsRounds,
    //     permissions: [Admin],
    //     exact: true
    // },

    // //Account
    // {
    //     path: APP_ROUTES.UPDATE_ACCOUNT_PROFILE,
    //     component: UpdateAdminProfile,
    //     permissions: [Admin],
    //     exact: true
    // },
    // {
    //     path: APP_ROUTES.ACCOUNT,
    //     component: Account,
    //     permissions: [Admin],
    //     exact: false
    // },

    // {
    //     path: APP_ROUTES.ACCOUNT_PROFILE,
    //     component: AdminProfile,
    //     permissions: [Admin],
    //     exact: true
    // },

    // {
    //     path: APP_ROUTES.ACCOUNT_CHANGE_PASSWORD,
    //     component: ChangePassword,
    //     permissions: [Admin],
    //     exact: true
    // }
];

export { authProtectedRoutes };
