import { Label } from "reactstrap"

export default function Filters ({
  activeTab = () => {},
  tabClickHandler = () => {},
  config= null,
  label="",
  className= "",
}) {

  const clickTabHandler = (id) => {
    tabClickHandler(id)
  }

  const firstLastTabClasses = (id) => {
    let lastTabId = config[config.length - 1]['id']
    console.log('l', lastTabId)
    switch(id){
      case 1:
        return "filter-first-tab"
      case lastTabId:
        return "filter-last-tab"
      default: 
        return ""
    }
  }

  const findActiveTab = (id) => {
    if(activeTab === id) return "active-tab"
  }
  return(<>
  <div className={`d-flex  align-items-center ${className}`}>
    <Label className="mb-0 me-2">{label}</Label>
    <div className="d-flex">
      {
        config.map( el => (
          <div 
          className={`${firstLastTabClasses(el.id)} ${findActiveTab(el.id)} filter-tab`} 
          key={el.id} 
          onClick={(e) => clickTabHandler(el.id)}>
            {el.title}
          </div>
        ))
      }
    </div>
  </div>
  </>)
}

